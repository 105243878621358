import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const checkAuth = async function(to, from, next) {
	if(!window.localStorage.getItem("resinToken") || window.localStorage.getItem("resinToken") == null || !window.localStorage.getItem("resinToken") == 'null') {
	  window.location.href = "/";
	}else{
	  next();
	}
}

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	redirect: '/dashboards/',
	// },
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Overall',
			sidebarMap: ['dashboard'],
			breadcrumbs: ['Dashboard', "Overall"],
		},
		component: () => import('../views/Dashboard/Index.vue'),
	},
	
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},

	{
		path: '/',
		name: 'Sign-In',
		meta: {
			layoutClass: '',
			title: 'sign-in',
		},
		component: () => import('../views/Authentication/SignIn.vue'),
	},

	{
		path: '/login',
		name: 'Login',
		meta: {
			layoutClass: '',
			title: 'login',
		},
		component: () => import('../views/Authentication/SignIn.vue'),
	},


	// STAFF MANAGEMENT
	{
		path: '/staff-management/teachers',
		name: 'Teacher',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Teacher',
			sidebarMap: ['staff-management'],
			breadcrumbs: ['Staff Management', 'Teacher'],
		},
		component: () => import('../views/Staff/Teacher.vue'),
	},
	{
		path: '/staff-management/teachers/add-new',
		name: 'Add Teacher',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Add Teacher',
			sidebarMap: ['staff-management'],
			breadcrumbs: ['Staff Management', 'Add Teacher'],
		},
		component: () => import('../views/Staff/AddTeacher.vue'),
	},
	{
		path: '/staff-management/teachers/view/:uuid',
		name: 'View Teacher',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'View Teacher',
			sidebarMap: ['staff-management'],
			breadcrumbs: ['Staff Management', 'View Teacher'],
		},
		component: () => import('../views/Staff/ViewTeacher.vue'),
	},
	{
		path: '/staff-management/teachers/edit/:uuid',
		name: 'Edit Teacher',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Edit Teacher',
			sidebarMap: ['staff-management'],
			breadcrumbs: ['Staff Management', 'Edit Teacher'],
		},
		component: () => import('../views/Staff/EditTeacher.vue'),
	},


	// STUDENT MANAGEMENT
	{
		path: '/student-management/students',
		name: 'Student',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Student',
			sidebarMap: ['student-management'],
			breadcrumbs: ['Student Management', 'Student'],
		},
		component: () => import('../views/Student/Student.vue'),
	},
	{
		path: '/student-management/students/add-new',
		name: 'Add Student',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Add Student',
			sidebarMap: ['student-management'],
			breadcrumbs: ['Student Management', 'Add Student'],
		},
		component: () => import('../views/Student/AddStudent.vue'),
	},
	{
		path: '/student-management/students/view/:uuid',
		name: 'View Student',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'View Student',
			sidebarMap: ['student-management'],
			breadcrumbs: ['Student Management', 'View Student'],
		},
		component: () => import('../views/Student/ViewStudent.vue'),
	},
	{
		path: '/student-management/students/edit/:uuid',
		name: 'Edit Student',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Edit Student',
			sidebarMap: ['student-management'],
			breadcrumbs: ['Student Management', 'Edit Student'],
		},
		component: () => import('../views/Student/EditStudent.vue'),
	},




	// CLASSROOM MANAGEMENT
	{
		path: '/classroom-management/classrooms',
		name: 'Classroom',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Classroom',
			sidebarMap: ['classroom-management'],
			breadcrumbs: ['Classroom Management', 'Classroom'],
		},
		component: () => import('../views/Classroom/Classroom.vue'),
	},
	// {
	// 	path: '/classroom-management/classrooms/add-new',
	// 	name: 'Add Classroom',
	// 	layout: "dashboard",
	// beforeEnter: checkAuth,// 	
	// meta: {
	// 		title: 'Add Classroom',
	// 		sidebarMap: ['classroom-management'],
	// 		breadcrumbs: ['Classroom Management', 'Add Classroom'],
	// 	},
	// 	component: () => import('../views/Classroom/AddClassroom.vue'),
	// },
	{
		path: '/classroom-management/classrooms/view/:uuid',
		name: 'View Classroom',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'View Classroom',
			sidebarMap: ['classroom-management'],
			breadcrumbs: ['Classroom Management', 'View Classroom'],
		},
		component: () => import('../views/Classroom/ViewClassroom.vue'),
	},
	// {
	// 	path: '/classroom-management/classrooms/edit/:uuid',
	// 	name: 'Edit Classroom',
	// 	layout: "dashboard",
	// beforeEnter: checkAuth,// 	
	// meta: {
	// 		title: 'Edit Classroom',
	// 		sidebarMap: ['classroom-management'],
	// 		breadcrumbs: ['Classroom Management', 'Edit Classroom'],
	// 	},
	// 	component: () => import('../views/Classroom/EditClassroom.vue'),
	// },



	//ACADEMIC SETTINGS
	{
		path: '/academic-settings/academic-years',
		name: 'Academic Year',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Academic Year',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Academic Year'],
		},
		component: () => import('../views/Settings/AcademicYear.vue'),
	},
	{
		path: '/academic-settings/education-levels',
		name: 'Education Level',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Education Level',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Education Level'],
		},
		component: () => import('../views/Settings/EducationLevel.vue'),
	},
	{
		path: '/academic-settings/grades',
		name: 'Grade',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Grade',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Grade'],
		},
		component: () => import('../views/Settings/Grade.vue'),
	},
	{
		path: '/academic-settings/subjects',
		name: 'Subject',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Subject',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Subject'],
		},
		component: () => import('../views/Settings/Subject.vue'),
	},
	{
		path: '/academic-settings/sections',
		name: 'Section',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Section',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Section'],
		},
		component: () => import('../views/Settings/Section.vue'),
	},


	//GENERAL SETTINGS
	{
		path: '/general-settings/designations',
		name: 'Designation',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Designation',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Designation'],
		},
		component: () => import('../views/Settings/Designation.vue'),
	},
	{
		path: '/general-settings/employee-types',
		name: 'Employee Type',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Employee Type',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Employee Type'],
		},
		component: () => import('../views/Settings/EmployeeType.vue'),
	},
	{
		path: '/general-settings/relationships',
		name: 'Relationship',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Relationship',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Relationship'],
		},
		component: () => import('../views/Settings/Relationship.vue'),
	},
	{
		path: '/general-settings/salutations',
		name: 'Salutation',
		layout: "dashboard",
		beforeEnter: checkAuth,
		meta: {
			title: 'Salutation',
			sidebarMap: ['settings'],
			breadcrumbs: ['Settings', 'Salutation'],
		},
		component: () => import('../views/Settings/Salutation.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history', 
	base: "/",//process.env.BASE_URL
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
