<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand text-center"><img src="images/logo.png" style="width: 100px" alt=""> <span></span></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline"
      			:open-keys="openKeys"
				@openChange="onOpenChange">
				<a-sub-menu key="dashboard" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="dashboard" theme="filled" class="m-0" />
						</span>
						<span class="label">Dashboard</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/dashboard">
								<span class="label">Overall</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>

				<a-menu-item class="menu-item-header">
					Pages
				</a-menu-item>

				<a-sub-menu key="student-management" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="lock" theme="filled" class="m-0" />
						</span>
						<span class="label">Student Mgt</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/student-management/students">
								<span class="label">Students</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>

				<a-sub-menu key="staff-management" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="lock" theme="filled" class="m-0" />
						</span>
						<span class="label">Staff Mgt</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/staff-management/teachers">
								<span class="label">Teachers</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>
				
				<a-sub-menu key="classroom-management" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="lock" theme="filled" class="m-0" />
						</span>
						<span class="label">Classroom Mgt</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/classroom-management/classrooms">
								<span class="label">Classroom</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>

				<a-sub-menu key="academic-settings" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="lock" theme="filled" class="m-0" />
						</span>
						<span class="label">Academic Settings</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/academic-settings/academic-years">
								<span class="label">Academic Years</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/academic-settings/education-levels">
								<span class="label">Education Level</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/academic-settings/grades">
								<span class="label">Grades</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/academic-settings/subjects">
								<span class="label">Subject</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/academic-settings/sections">
								<span class="label">Section</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>

				<a-sub-menu key="general-settings" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="lock" theme="filled" class="m-0" />
						</span>
						<span class="label">General Settings</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/general-settings/designations">
								<span class="label">Designation</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/general-settings/employee-types">
								<span class="label">Employee Type</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/general-settings/salutations">
								<span class="label">Salutations</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/general-settings/relationships">
								<span class="label">Relationship</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>
				
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "white",
			},
		},
		data() {
			return {
      			rootSubmenuKeys: ['dashboards', 'pages', 'applications', 'ecommerce', 'authentication', 'student-management', 'staff-management', 'classroom-management', 'academic-settings', 'general-settings', 'basic', 'components', 'changelog'],
				openKeys: this.$route.meta.sidebarMap,
			}
		},
		methods: {
			onOpenChange(openKeys)
			{
				const latestOpenKey = openKeys.find( key => this.openKeys.indexOf( key ) === -1) ;

				if ( this.rootSubmenuKeys.indexOf( latestOpenKey ) === -1 )
				{
					this.openKeys = openKeys;
				}
				else
				{
					this.openKeys = latestOpenKey ? [ latestOpenKey ] : [] ;
				}
			},
		},
	})

</script>
